import bigLogo from './aisha-aliyu-bigger-logo.svg';
import logo from './aisha-aliyu-small-logo.svg';
import './globals.css';
import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent } from "./components/ui/card";
import { Button } from "./components/ui/button";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "./components/ui/tooltip";

import { Brain, Layers, Code, Wand2, Linkedin, Pen } from 'lucide-react';

const TypewriterEffect = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const typewriterInterval = setInterval(() => {
      if (currentIndex < text.length) {
        setDisplayText((prev) => prev + text[currentIndex]);
        setCurrentIndex((prev) => prev + 1);
      } else {
        clearInterval(typewriterInterval);
        setTimeout(() => {
          setDisplayText('');
          setCurrentIndex(0);
        }, 3000); // Pause for 3 seconds before restarting
      }
    }, 50);

    return () => clearInterval(typewriterInterval);
  }, [text, currentIndex]);

  return (
    <span>
      {displayText}
      <span className="animate-pulse">|</span>
    </span>
  );
};

const FeatureCard = ({ Icon, title, description }) => (
  <Card className="transform hover:scale-105 transition-all duration-300 ease-in-out hover:shadow-lg">
    <CardContent className="p-6">
      <div className="flex flex-col items-center text-center space-y-6">
        <div className="pt-4">
          <Icon className="h-10 w-10 text-primary" />
        </div>
        <h3 className="font-semibold text-lg">{title}</h3>
        <p className="text-muted-foreground text-sm">{description}</p>
      </div>
    </CardContent>
  </Card>
);

const randomFacts = [
  "I love Fintech. It's my jam! I love it's disruptive nature and how it can be a force for good (think unbanked populations in the global south) ",
  "I am anime head! Infact, chatgpt couldn't recommend any shounen or shouji anime cause I've seen so many",
  "I wear many hats, and do them quite well",
  "True story, I joined a sibos hackathon and was abandoned by my coders, long story short, I still submitted my AML idea built in Axure, wish I had generative A.I. to help then.",
  "I UX, I design (digital and print), I code (badly but good enough for testing and P.O.Cs, I run a dedicated server and not too shabby with server management. I essentially love tech"
];

export default function PersonalLanding() {
  const [scrollY, setScrollY] = useState(0);
  const [isBouncing, setIsBouncing] = useState(false); // Tracks bounce animation
  const logoRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll Animation Hook
  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY); // Update scroll position
      setIsBouncing(true); // Trigger bounce animation
      setTimeout(() => setIsBouncing(false), 1500); // Reset bounce after 1.5s
    };

    window.addEventListener('scroll', handleScroll); // Listen to scroll events
    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup listener
    };
  }, []);

  useEffect(() => {
    if (logoRef.current) {
      const movement = scrollY * 0.05; // Subtle parallax effect
      logoRef.current.style.transform = `translateY(${movement}px)`;
    }
  }, [scrollY]);


  useEffect(() => {
    const smoothScroll = (e) => {
      e.preventDefault();
      const targetId = e.currentTarget.getAttribute('href').slice(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: 'smooth'
        });
      }
    };

    const aboutLink = document.querySelector('a[href="#about"]');
    if (aboutLink) {
      aboutLink.addEventListener('click', smoothScroll);
    }

    return () => {
      if (aboutLink) {
        aboutLink.removeEventListener('click', smoothScroll);
      }
    };
  }, []);

  return (
    <div className="min-h-screen bg-background">
      <div className="flex flex-col min-h-screen">
        {/* Navigation */}
        <nav className="border-b bg-background/95">
          <div className="container mx-auto px-6 py-4 flex justify-between items-center">
            <div className="flex items-center space-x-4">
              <img 
                src={logo}
                alt="Aisha Aliyu Logo"
                className="w-12 h-12 rounded-full mx-auto"
              />
              <span className="font-semibold">Aisha Aliyu</span>
            </div>
            <div className="flex space-x-4">
              <a href="#about">
                <Button variant="ghost">About</Button>
              </a>
            </div>
          </div>
        </nav>

        {/* Main Content */}
        <main className="flex-grow">
          {/* Hero Section */}
          <section className="container mx-auto px-6 py-24 text-center">
            <h1 className="text-4xl md:text-5xl lg:text-5xl font-mono font-bold tracking-tight mb-6">
              <TypewriterEffect 
                text="Part digital alchemist, part coder* & full time designer"
              />
            </h1>
            <p className="text-xl text-muted-foreground mb-4 max-w-2xl mx-auto">
              Lead Product and UX Designer crafting intuitive 
              and impactful digital experiences 
            </p>
            <p className="text-sm text-muted-foreground mb-8 max-w-2xl mx-auto">
              *High fidelity prototyping using Axure RP 11 and proof of concepts using modern frameworks. Clean code not guaranteed. 😉
            </p>
            <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <img
                  ref={logoRef}
                  src={bigLogo}
                  alt="Aisha Aliyu Logo"
                  className="w-28 h-42 mx-auto text-center transition-transform duration-300 ease-out"
                  style={{
                    display: 'block',
                    margin: '0 auto',
                    paddingTop: 0,
                    paddingBottom: 10
                  }}
                />
              </TooltipTrigger>
              <TooltipContent className="max-w-[200px] p-4 bg-black text-white rounded-lg shadow-md">
                <p className="font-bold mb-2">Random Fact</p>
                <p>{randomFacts[Math.floor(Math.random() * randomFacts.length)]}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <p><p></p></p>
            <div className="flex justify-center gap-4">
              <a href="https://www.linkedin.com/in/aishaaliyu/" target="_blank" rel="noopener noreferrer">
                <Button size="lg">
                  Let's connect
                  <Linkedin className="ml-2 h-4 w-4" />
                </Button>
              </a>
              <a href="https://codepen.io/aliyuafs" target="_blank" rel="noopener noreferrer">
                <Button variant="outline" size="lg">
                  CodePen
                  <Pen className="ml-2 h-4 w-4" />
                </Button>
              </a>  
            </div>
          </section>

          {/* Expertise Grid */}
          <section className="container mx-auto px-6 py-16">

            <h2 className="text-3xl font-bold text-center mb-12">Areas of Expertise</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
              <FeatureCard 
                Icon={Brain}
                title="UX Design & Strategy"
                description="Aligning design with business goals. Turning complex problems into elegant solutions through user-centred practice."
              />
              <FeatureCard 
                Icon={Layers}
                title="Product Design & Management"
                description="Crafting intuitive and impactful interfaces that deliver meaningful user experiences, with expertise spaning the entire product lifecycle."
              />
              <FeatureCard 
                Icon={Code}
                title="Prototyper/Coder*"
                description="Bridging design and implementation with high-fidelity prototyping & technical skills"
              />
              <FeatureCard 
                Icon={Wand2}
                title="Digital Alchemy"
                description="Transforming ideas into innovative solutions through emerging technologies and practical experimentation"
              />
            </div>
          </section>

          {/* About Section */}
          <section className="bg-primary/5 py-24" id="about">
            <div className="container mx-auto px-6">
              <div className="max-w-3xl mx-auto text-center">
             
                <h2 className="text-3xl font-bold mb-6">About Me</h2>
                <p className="text-lg text-muted-foreground mb-6">
                  From an accidental start to a decade of mastery, I am a Lead Product & UX Designer dedicated to crafting intuitive and impactful user experiences. My expertise spans the entire product lifecycle—from initial ideation, through iterative design and usability testing, to final implementation and post-launch improvements.
                </p>
                <p className="text-lg text-muted-foreground mb-6">
                  Skilled in both structured and dynamic development environments, I bring a rich background in print, digital interaction, branding, and web development to every project. My work is fuelled by a passion for fintech, technology, and a side of heavy lifting.
                </p>
                <p className="text-lg text-muted-foreground mb-6">
                  Having run my own company and constantly learning, I am committed to pushing my craft and the boundaries of product design, embracing new challenges and technologies to deliver results.
                </p>
                <div className="flex justify-center space-x-4">
                  <a href="https://www.linkedin.com/in/aishaaliyu/" target="_blank" rel="noopener noreferrer">
                    <Button size="lg">
                      Let's connect
                      <Linkedin className="ml-2 h-4 w-4" />
                    </Button>
                  </a>
                  <a href="https://codepen.io/aliyuafs" target="_blank" rel="noopener noreferrer">
                    <Button variant="outline" size="lg">
                      CodePen
                      <Pen className="ml-2 h-4 w-4" />
                    </Button>
                  </a>  
                </div>
              </div>
            </div>
          </section>
        </main>

        {/* Footer */}
        <footer className="border-t bg-background">
          <div className="container mx-auto px-6 py-8 text-center text-muted-foreground">
            <p>© 19XX - 2024 Aisha 'Digo' Aliyu. All rights reserved. </p>
            <small>This website was made when I was learning react</small>
          </div>
        </footer>
      </div>
    </div>
  );
}
